import React from "react";
import { useTranslations } from "next-intl";

import { ButtonVariant } from "modules/DesignSystem/components/Button";
import { Audiobook } from "resources/AudiotekaApi";
import { NewIcon } from "components/NewIcon";

import { ActionButton, Box, Title } from "../components";
import { InfoIcon } from "../info-icon";
import { PlayButton } from "../buttons";
import { DownloadBox } from "./download/download-box";
import css from "./product-on-shelf.module.scss";

interface Props {
  audiobook: Audiobook;
  canDownload: boolean;
}

export const ProductOnShelf = ({ audiobook, canDownload }: Props) => {
  const t = useTranslations();

  return (
    <>
      <Box>
        <Title>
          <NewIcon className={css.shelfIcon} icon="shelf" width="24" height="24" />
          {t("product.price_box.title.shelf")}
          {canDownload && <InfoIcon info={t("product.price_box.info.shelf_mp3")} />}
        </Title>
        <PlayButton audiobook={audiobook} />
        <ActionButton variant={ButtonVariant.outlined} route="shelf">
          <NewIcon icon="shelf" width="20" height="20" />
          {t("product.price_box.cta.shelf")}
        </ActionButton>
      </Box>
      {canDownload && <DownloadBox audiobook={audiobook} />}
    </>
  );
};
