import React from "react";
import classNames from "classnames";

import Button from "modules/DesignSystem/components/Button";

import css from "./components.module.scss";

export function Box({ className, ...props }: React.ComponentProps<"div">) {
  return <div className={classNames(className, css.box)} {...props} />;
}

export function Title({ className, secondary, ...props }: React.ComponentProps<"p"> & { secondary?: boolean }) {
  return (
    <p
      className={classNames(className, css.title, {
        [css.title__secondary]: secondary,
      })}
      {...props}
    />
  );
}

export function ClubIconWrapper({ className, ...props }: React.ComponentProps<"span">) {
  return <span className={classNames(className, css.clubIconWrapper)} {...props} />;
}

export function Trial({ className, ...props }: React.ComponentProps<"p">) {
  return <p className={classNames(className, css.trial)} {...props} />;
}

export function Price({
  as: Tag = "p",
  className,
  club,
  old,
  children,
}: {
  as?: "p" | "span";
  className?: string;
  club?: boolean;
  old?: boolean;
  children: React.ReactNode;
}) {
  return (
    <Tag
      className={classNames(className, css.price, {
        [css.price__club]: club,
        [css.price__old]: old,
      })}
    >
      {children}
    </Tag>
  );
}

export function LowestPrice({ className, ...props }: React.ComponentProps<"p">) {
  return <p className={classNames(className, css.lowestPrice)} {...props} />;
}

export function ActionButton({ className, ...props }: React.ComponentProps<typeof Button>) {
  return <Button className={classNames(className, css.actionButton)} {...props} />;
}
