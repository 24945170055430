import React from "react";
import { useTranslations } from "next-intl";

import { Audiobook, LicenseChannels } from "resources/AudiotekaApi";
import { NewIcon } from "components/NewIcon";
import { KlubLogo } from "components/klub-logo";

import { Box, ClubIconWrapper, LowestPrice, Price, Title, Trial } from "./components";
import { AddToCartButton, ClubJoinButton } from "./buttons";
import type { PricesWithCurrency } from "../../../utils";

interface Props {
  audiobook: Audiobook;
  licenses: LicenseChannels;
  price: PricesWithCurrency;
  clubListen: boolean | null;
}

const buyOptions = {
  clubPromo: {
    icon: "club-logo",
    title: "product.price_box.title.buy_with_club",
  },
  regular: {
    icon: "add-to-cart",
    title: "product.price_box.title.buy",
  },
} as const;

export const ProductToBuy = ({ audiobook, licenses, price, clubListen }: Props) => {
  const t = useTranslations();

  const withClubBox = (price.club && price.club !== price.final) || clubListen;
  const isPromo = price.final !== price.base;
  const isClubPromo = isPromo && price.club === price.final;

  const buyOption = isClubPromo ? "clubPromo" : "regular";

  return (
    <>
      {withClubBox && (
        <Box>
          <Title secondary>
            {clubListen
              ? t("product.price_box.title.club_free")
              : t("product.price_box.title.club_buy", { price: price.club })}
            <ClubIconWrapper>
              <KlubLogo version="two-line" width="100%" />
            </ClubIconWrapper>
          </Title>
          <ClubJoinButton />
          <Trial>{t("product.price_box.club_trial")}</Trial>
        </Box>
      )}
      <Box>
        <Title>
          <NewIcon icon={buyOptions[buyOption].icon} width="24" height="24" />
          {t(buyOptions[buyOption].title)}
        </Title>
        <Price club={isClubPromo}>
          {price.final}
          {isPromo && !isClubPromo ? (
            <Price as="span" old>
              {price.base}
            </Price>
          ) : null}
        </Price>
        <AddToCartButton audiobook={audiobook} licenses={licenses} />
        {price.lowest && isPromo && !isClubPromo ? (
          <LowestPrice>{t("product.lowest_price", { price: price.lowest.replace(/ /g, "\xa0") })}</LowestPrice>
        ) : null}
      </Box>
    </>
  );
};
